@import '~antd/dist/antd.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body * {
    font-family: 'Roboto', sans-serif;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: #002766;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.hide-small {
    display: none;
}

@media (min-width: 768px) {
    .hide-small {
        display: block;
    }

    .hide-large {
        display: none;
    }
}

.fadeInUp {
    animation-duration: 0.45s;
    animation-fill-mode: both;
    animation-name: fadeInUp;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
